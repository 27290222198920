import ScrollMagic from 'scrollmagic';
import 'scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators';

// init controller
var controller = new ScrollMagic.Controller();

$(function () { // wait for document ready
  // build scene
  var scene = new ScrollMagic.Scene({duration: 300})
    .setPin("#pin1")
    .addIndicators({name: "1 (duration: 300)"}) // add indicators (requires plugin)
    .addTo(controller);
});
