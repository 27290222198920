// import external dependencies
import jquery from 'jquery';

declare global {
  interface Window {
    $: typeof jquery;
    jQuery: typeof jquery;
  }
}

window.$ = window.jQuery = jquery;

// import Modal from 'bootstrap/js/dist/modal';
import './modules/magicscroll';
